import React from "react";
import { Link } from "react-router-dom";


const Schedule = () => {
  return (
    <div className="container pt-3 pb-3">
      <div className="row">
        <div className="col-lg-6">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="pt-70">
              <h1 className="">You can Schedule a call </h1>
              <p className="info">
                Please schedule an appointment for me at your earliest
                convenience. I'm open to meeting on a date and time that works
                best for you. Your flexibility is highly appreciated. I'm
                looking forward to our upcoming meeting and the opportunity to
                connect. Thank you in advance for your assistance and
                coordination.
              </p>
              <div style={{ display: "flex" }}>
                <Link to="/appointment">
                  <button className="b-btn" style={{ marginLeft: "0px" }}>
                    Schedule a call{" "}
                    {/* <i class="fa-solid fa-calendar-days"></i> */}
                    {/* <i class="fa-regular fa-calendar-days"></i> */}
                    <i
                      className="fa-regular fa-calendar-days"
                      style={{ color: "#ffffff",marginLeft:"10px" }}
                    ></i>{" "}
                  </button>
                </Link>
                <a target="_blank"  href="https://www.upwork.com/freelancers/mhamzayasin">
                  <button className="b-btn">
                    Hire on upwork
                    {/* <i class="fa-brands fa-square-upwork"></i> */}
                    {/* <i class="fa-brands fa-upwork"></i> */}
                    <i
                      class="fa-brands fa-upwork"
                      style={{ color: "#ffffff" ,marginLeft:"10px" }}
                    ></i>{" "}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img src="/assets/img/images/calling.jpg" alt="calling" />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
