import React, { useState } from "react";
import Brand from "../landingPage/Brand";
import Testimonial from "../landingPage/Testimonial";
import CountUp from "react-countup";
import CompnayArea from "../landingPage/CompnayArea";
import CounterArea from "../landingPage/CounterArea";
import ServicesMore from "./ServicesMore";
import Teams from "./Teams";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Aboutus = () => {
  // const [route, setRouteName] = useState("");
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const paramValue = queryParams.get("route");
  // console.log("data:", paramValue);
  // useEffect(() => {
  //   setRouteName(paramValue);
  // }, [paramValue]);

  // useEffect(() => {
  //   if (route === "services") {
  //     window.scrollTo({ top: 1700, behavior: "smooth" });
  //   }
  // }, [route]);

  return (
    <div>
      <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>About Us software development services </title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
      </div>
      <main>
        {/* <!-- breadcrumb-area --> */}
        <section class="breadcrumb-area pt-175 pb-70">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="breadcrumb-content">
                  <h2 class="title">About Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        About Us
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap">
            <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
            <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
          </div>
        </section>
        {/* <!-- breadcrumb-area-end -->

<!-- counter-area --> */}
        <CounterArea />
        {/* <!-- counter-area-two -->

<!-- team-area --> */}
        <Teams />
        {/* <!-- team-area-end -->

<!-- consultation-area --> */}
        {/* <section class="consultation-area consultation-area-two pt-120 pb-120">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7">
                <div
                  class="consultation-content"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div class="section-title mb-25">
                    <span class="sub-title">95% Accuracy</span>
                    <h2 class="title">
                      Worldwide Best Digital Marketing Agency
                    </h2>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, sed nulla ante amet, elementum
                    tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac
                    scelerisque nibh dolores consectetuer, nulla aptent est
                    pede. Scelerisque euismod varius mi, congue eget sed
                    vestibulum, ornare cras sed nec.
                  </p>
                  <div class="consultation-list">
                    <ul class="list-wrap">
                      <li>
                        <h6 class="title">12X</h6>
                        <p>
                          Faster Order <span>Processing</span>
                        </p>
                      </li>
                      <li>
                        <h6 class="title">99%</h6>
                        <p>
                          Processing <span>Accuracy</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div
                  class="consultation-form-wrap"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h4 class="title">Free Consultation</h4>
                  <form action="#">
                    <div class="form-grp">
                      <input type="text" placeholder="Name" />
                    </div>
                    <div class="form-grp">
                      <input type="email" placeholder="Email Address" />
                    </div>
                    <div class="form-grp">
                      <input type="text" placeholder="Phone Number" />
                    </div>
                    <div class="form-grp">
                      <select
                        id="shortBy"
                        name="select"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">Subject</option>
                        <option>Subject One</option>
                        <option>Subject Two</option>
                        <option>Subject Three</option>
                        <option>Subject Four</option>
                      </select>
                    </div>
                    <button class="btn" type="submit">
                      Consultation
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="consultation-shape-wrap">
            <img
              src="assets/img/images/consultation_shape01.png"
              alt=""
              class="shape-one ribbonRotate"
            />
            <img
              src="assets/img/images/consultation_shape02.png"
              alt=""
              class="shape-two float-bob-x"
            />
          </div>
        </section> */}
        {/* <!-- consultation-area-end -->

<!-- services-area --> */}
        {/* <ServicesMore /> */}
        {/* <!-- services-area-end -->

<!-- success-area --> */}
        <section class="success-area pb-120">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 order-0 order-lg-2">
                <div
                  class="success-img"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <img src="assets/img/images/success.png" alt="" />
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="success-content"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <div class="section-title title-style-two mb-30">
                    <span class="sub-title">Company Success</span>
                    <h2 class="title">
                      A Company’s Success is An Ongoing Stream of Happiness
                    </h2>
                  </div>
                  <p>
                    Our Company’s Success: Achieving a Remarkable 90% Success
                    Rate through Expertise, Innovation, and Unwavering Client
                    Satisfaction, driving growth, profitability, and
                    establishing a strong market presence.
                  </p>
                  <div class="success-list">
                    <ul class="list-wrap">
                      <li>
                        <div class="content">
                          <img
                            src="assets/img/icon/success_icon01.png"
                            alt=""
                          />
                          <span>App Development 85%</span>
                        </div>
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{ width: "85%" }}
                            aria-valuenow="85"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div class="content">
                          <img
                            src="assets/img/icon/success_icon02.png"
                            alt=""
                          />
                          <span>Website Development 75%</span>
                        </div>
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div class="content">
                          <img
                            src="assets/img/icon/success_icon03.png"
                            alt=""
                          />
                          <span>Product Development 95%</span>
                        </div>
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{ width: "95%" }}
                            aria-valuenow="95"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </li>
                      <li>
                        <div class="content">
                          <img
                            src="assets/img/icon/success_icon04.png"
                            alt=""
                          />
                          <span> Blockchain Development 99%</span>
                        </div>
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{ width: "99%" }}
                            aria-valuenow="99"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="success-shape-wrap">
            <img src="assets/img/images/success_shape01.png" alt="" />
            <img src="assets/img/images/success_shape02.png" alt="" />
          </div>
        </section>
        {/* <!-- success-area-end -->

<!-- history-area --> */}
        {/* <section class="history-area">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-lg-8">
                <div
                  class="section-title white-title text-center mb-45"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <span class="sub-title">Our Company</span>
                  <h2 class="title">Company History</h2>
                  <p>
                    Quantum Bases Capital, a well-established IT solution
                    provider in Pakistan, offers a wide range of services
                    including mobile and web application development, blockchain
                    development, cloud services, SEO, and web design. They
                    prioritize customization, professional guidance, and client
                    satisfaction to enhance online businesses effectively.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="history-inner"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div class="history-img">
                <img src="assets/img/images/history.png" alt="" />
              </div>
              <div class="row g-0 justify-content-end">
                <div class="col-lg-6">
                  <div class="history-content">
                    <h2 class="title">
                      The Intention was to Establish The Company
                    </h2>
                    <p>
                      Our mission is to build a strong and successful business
                      by offering innovative solutions and exceptional services.
                      With a focus on excellence, client satisfaction, and
                      market growth, we aim to make a lasting impact in the
                      industry.
                    </p>
                    <ul class="list-wrap">
                      <li>
                        <i class="far fa-check"></i>Everyone can design at low
                        cost
                      </li>
                      <li>
                        <i class="far fa-check"></i>Designing in a different way
                      </li>
                      <li>
                        <i class="far fa-check"></i>Our development sill be world
                        wide best designs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="history-roadmap-wrap">
              <div class="roadmap-line-shape">
                <span class="dot-one pulse-one"></span>
                <span class="dot-two pulse-two"></span>
              </div>
              <ul class="list-wrap">
                <li
                  class="wow fadeInDown"
                  data-wow-delay=".2s"
                  data-wow-duration="1.5s"
                >
                  <span class="dot pulse-three"></span>
                  <div class="content">
                    <h5 class="title">2020</h5>
                    <p>January 14 th</p>
                  </div>
                </li>
                <li
                  class="wow fadeInUp"
                  data-wow-delay=".2s"
                  data-wow-duration="1.5s"
                >
                  <span class="dot pulse-four"></span>
                  <div class="content">
                    <h5 class="title">2021</h5>
                    <p>January 14 th</p>
                  </div>
                </li>
                <li
                  class="wow fadeInDown"
                  data-wow-delay=".2s"
                  data-wow-duration="1.5s"
                >
                  <span class="dot pulse-five"></span>
                  <div class="content">
                    <h5 class="title">2022</h5>
                    <p>January 14 th</p>
                  </div>
                </li>
                <li
                  class="wow fadeInUp"
                  data-wow-delay=".2s"
                  data-wow-duration="1.5s"
                >
                  <span class="dot pulse-six"></span>
                  <div class="content">
                    <h5 class="title">2023</h5>
                    <p>January 14 th</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="history-shape-wrap">
            <img src="assets/img/images/history_shape01.png" alt="" />
            <img src="assets/img/images/history_shape02.png" alt="" />
          </div>
        </section> */}
        {/* <!-- history-area-end -->

<!-- testimonial-area --> */}
        {/* <section class="testimonial-area-three testimonial-area-four">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="section-title title-style-two mb-45">
                  <h2 class="title">What Our Client’s Say</h2>
                </div>
              </div>
              <div class="col-md-4">
                <div class="testimonial-nav">
                  <button class="swiper-button-prev"></button>
                  <button class="swiper-button-next"></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="swiper-container testimonial-active-three">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <div class="testimonial-item-three">
                        <div class="testimonial-thumb-three">
                          <img
                            src="assets/img/images/testimonial_avatar01.png"
                            alt=""
                          />
                        </div>
                        <div class="testimonial-content-three">
                          <h4 class="title">James Botosh</h4>
                          <span>Product Designer</span>
                          <p>
                            “We seek to get involved early in the design phase
                            so that we can manage the project more efficiently,
                            provide effective building solutions”
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="testimonial-item-three">
                        <div class="testimonial-thumb-three">
                          <img
                            src="assets/img/images/testimonial_avatar02.png"
                            alt=""
                          />
                        </div>
                        <div class="testimonial-content-three">
                          <h4 class="title">James Botosh</h4>
                          <span>Product Designer</span>
                          <p>
                            “We seek to get involved early in the design phase
                            so that we can manage the project more efficiently,
                            provide effective building solutions”
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="swiper-slide">
                      <div class="testimonial-item-three">
                        <div class="testimonial-thumb-three">
                          <img
                            src="assets/img/images/testimonial_avatar03.png"
                            alt=""
                          />
                        </div>
                        <div class="testimonial-content-three">
                          <h4 class="title">James Botosh</h4>
                          <span>Product Designer</span>
                          <p>
                            “We seek to get involved early in the design phase
                            so that we can manage the project more efficiently,
                            provide effective building solutions”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Testimonial />
        {/* <!-- testimonial-area-end -->

<!-- brand-area --> */}
        <Brand style={{ marginBottom: "50px" }} />
        {/* <!-- brand-area-end --> */}
      </main>
    </div>
  );
};

export default Aboutus;
