import React, { useState } from "react";
import { Link } from "react-router-dom";
// import MyVerticallyCenteredModal from '../videoModal/MyVerticallyCenteredModal';
// import Button from 'react-bootstrap/Button';
// import my
// import MyVerticallyCenteredModal from '../videoModal/MyVerticallyCenteredModal';
// import MyVerticallyCenteredModal from '../VideoModal/MyVerticallyCenteredModal';
// import MyVerticallyCenteredModal from '../Modal/vedioModal';

import VedioModal from "../Modal/vedioModal";
const HeaderContent = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      {/* <MyVerticallyCenteredModal /> */}
      <VedioModal show={modalShow} onHide={() => setModalShow(false)} />
      <section className="slider-area">
        <div className="slider-active">
          <div className="single-slider">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-0 order-lg-2">
                  <div
                    className="slider-img text-end"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <img src="assets/img/images/header-img.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="slider-content"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <h1
                      className="title"
                      style={{ textTransform: "capitalize" }}
                    >
                      Customized software development services
                    </h1>
                    <p>
                      Quantum Bases Capital thrives on successful
                      collaborations, specializing in technology, professional
                      services, and social media ventures{" "}
                      <Link
                        style={{
                          fontSize: " 15px",
                          textDecoration: "underline",
                        }}
                        to="/blogs"
                      >
                        Read more
                      </Link>
                    </p>
                    <div className="slider-btn">
                      <a
                        className="p-btn"
                        style={{ color: "white", fontWeight: 600 }}
                        onClick={() => setModalShow(true)}
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-shape-wrap">
          <img src="assets/img/slider/slider_shape01.png" alt="" />
          <img src="assets/img/slider/slider_shape02.png" alt="" />
          <img src="assets/img/slider/slider_shape03.png" alt="" />
          <img src="assets/img/slider/slider_shape04.png" alt="" />
          <img src="assets/img/slider/slider_shape05.png" alt="" />
          <img src="assets/img/slider/slider_shape06.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default HeaderContent;
