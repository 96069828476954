import React, { useEffect, useState } from "react";
import Landing from "./component/landingPage/Landing";
import Aboutus from "./component/aboutus/Aboutus";
import Header from "./component/landingPage/Header";
import Footer from "./component/landingPage/Footer";
import ContactUs from "./component/contactus/ContactUs";
import Portfolio from "./component/Portfolio/Portfolio";
import ScrollToTop from "./component/scroll/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import FrontentDevelopment from "./component/ServicesDetails/FrontentDevelopment";
import BackendDevelopment from "./component/ServicesDetails/BackendDevelopment";
import BlochainDevelopment from "./component/ServicesDetails/BlochainDevelopment";
import Appointment from "./component/Appointment/Appointment";

import Porfoliodetails from "./component/PortfolioDetails/Porfoliodetails";
import CloudServices from "./component/ServicesDetails/CloudServices";
import UIUIX from "./component/ServicesDetails/UIUIX";
import SEO from "./component/ServicesDetails/SEO";
import SMM from "./component/ServicesDetails/SMM";

import Webdevelopment from "./component/ServicesDetails/Webdevelopment";
import Blogs from "./component/Blogs/Blogs";
import Blogsdetails from "./component/Blogs/Blogsdetails";

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS animations
    AOS.refresh(); // Refresh AOS animations
  }, []);
  return (
    <BrowserRouter>
      <Header></Header>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about-us" component={Aboutus} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route
          exact
          path="/frontent-development"
          component={FrontentDevelopment}
        />
        <Route
          exact
          path="/backend-development"
          component={BackendDevelopment}
        />
        <Route
          exact
          path="/blockchain-development"
          component={BlochainDevelopment}
        />
        <Route exact path="/cloud-services" component={CloudServices} />
        <Route exact path="/UIUX" component={UIUIX} />
        <Route exact path="/SEO" component={SEO} />
        <Route exact path="/SMM" component={SMM} />
        <Route exact path="/webdevelopment" component={Webdevelopment} />

        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/portfolio-detail" component={Porfoliodetails} />
        <Route exact path="/blogs-detail" component={Blogsdetails} />
        <Route exact path="/appointment" component={Appointment} />
        <Route exact path="/blogs" component={Blogs} />

        {/* <Route exact path="/portfolio/bitnautic" component={Bitnautic} /> */}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
