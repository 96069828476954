import React from "react";

import { Helmet } from "react-helmet";
const SMM = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>SMM software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <div>
        <div className="breadcrum-area details pt-175 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="pt-5 ">
                  <h1 className="title">Social Media Marketing</h1>
                  <p>
                    Amplify your business's social media brilliance through our
                    SMM services. Our dedicated team crafts captivating posts,
                    engages with your followers, and cultivates your online
                    footprint. Harness our expertise to transform your social
                    media into a dynamic hub that resonates with your audience.
                    With our strategic approach, watch your online presence
                    flourish as we curate content that sparks conversations and
                    resonates with your community. Let us orchestrate your
                    social media narrative, transforming it into an interactive
                    and vibrant space where your brand truly comes alive.
                    Embrace the power of effective social media management and
                    watch your online ecosystem thrive like never before.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <h1 className="title text-center pt-5 pb-5">Our Process</h1>
              <div className="col-lg-6">
                <img src="/assets/img/images/process.svg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="pt-40">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Creative Posts</h4>
                      <p>
                        Our team comes up with interesting and fun posts that
                        people will like. Our SMM services use pictures, videos,
                        and words to catch attention and keep your followers
                        engaged.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Connecting with Fans</h4>
                      <p>
                        We reply to comments, messages, and questions from your
                        followers. This makes them feel heard and valued,
                        building a strong relationship between you and your
                        audience.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Growing Your Presence</h4>
                      <p>
                        We use strategies to get more people to like, follow,
                        and share your social media pages. This helps your
                        business reach more people and become well-known.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h1 className="text-center pt-5 pb-5">Our SMM Services</h1>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4 mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/ux.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Creative Posts</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We come up with interesting and attractive posts for your
                    social media. These posts include pictures, videos, and
                    words that grab attention, engaging your audience
                    effectively. Our creative approach ensures a visually
                    appealing and compelling presence, enhancing your brand's
                    visibility and impact online.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/html.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Engagement Boost</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We reply to comments and messages from your followers,
                    fostering a sense of connection and engagement. This
                    interaction is invaluable in making them feel valued and
                    appreciated for their input. It also strengthens the bond
                    between you and your audience around your content.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/client.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Growing Followers</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We work on getting more people to like and follow your
                    social media pages, utilizing targeted strategies to expand
                    your online presence. By increasing engagement and
                    visibility, we help you reach a wider audience and boost
                    your brand's influence across various platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/responsive.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Content Planning</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    Our team plans a schedule for your posts, ensuring a
                    consistent flow of engaging content on your social media. We
                    strategically time posts to reach your audience effectively,
                    maintaining interest and interaction. This approach fosters
                    a vibrant online presence and driving engagement .
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/perform.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Ad Campaigns</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We create and manage ads on social media platforms to reach
                    a wider audience that attract potential customers. Our
                    strategic approach includes audience targeting, content
                    optimization, and performance tracking soo that to maximize
                    engagement and conversions.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/deploy.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Performance Tracking</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                   
We keep an eye on how well your website is doing in search results and make adjustments as needed. Our monitoring stratergy includes analyzing performance metrics, optimizing content, and implementing SEO strategies to improve visibility and ranking, ensuring sustained online success.
                  </p>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "90px 0" }}>
              <div className="col-lg-6">
                <h2 style={{ fontSize: "40px" }}>
                  Let’s build a product that gets results!
                </h2>
                <p style={{ textAlign: "justify" }}>
                  Elevate your social media game with improved SMM services and
                  watch your online presence rise quickly. Let's make your
                  social media shine brighter than ever before!
                </p>
                <div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: "white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Consulting Service</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: "white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>API Integrations</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>End to End Support</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>
                          Prototyping Expertise
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/assets/img/images/points.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMM;
