import Carousel from "better-react-carousel";
import React from "react";

const Brand = () => {
  return (
    <div>
      <div className="brand-area pt-55 pb-55">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="title">We Already Build Solution for...</span>
            </div>
          </div>
          <div className="row brand-active">
            {/* loop={true} autoplay={3000} */}
            <Carousel cols={6} rows={1} gap={10} loop={true} autoplay={3000}>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img
                      style={{ width: "70%" }}
                      src="assets/img/logo/clustai.png"
                      alt="clustai"
                    />
                  </a>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://www.aimining.ai/">
                    <img src="assets/img/logo/aimining.png" alt="aimining" />
                  </a>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img src="assets/img/logo/vorbit.svg" alt="Vorbit" />
                  </a>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img
                      src="assets/img/logo/SCGDEX.png"
                      alt="SCGDEX"
                      style={{ width: "70%" }}
                    />
                  </a>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img
                      src="assets/img/logo/stablout.png"
                      alt="Stablout"
                      style={{ width: "70%" }}
                    />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    <img src="assets/img/logo/BitNautic.png" alt="BitNautic" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img src="assets/img/logo/Nftcity.png" alt="Nftcity" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img src="assets/img/logo/Nucoin.png" alt="Nucoin" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img src="assets/img/logo/Nugenesis.png" alt="Nugenesis" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://clustai.net/">
                    {" "}
                    <img src="assets/img/logo/WEBPAYS.png" alt="WEBPAYS" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-play">
                  <a target="_blank" href="https://playappeconomy.com/">
                    {" "}
                    <img src="assets/img/logo/playapp.png" alt="playapp" />
                  </a>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="brand-item-two">
                  <a target="_blank" href="https://imining.com/">
                    <img src="assets/img/logo/imining.jpg" alt="imining" />
                  </a>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
