import React from "react";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
const Appointment = () => {
  return (
    <div className="breadcrum-area pt-175 pb-70">
<Helmet>
<meta charSet="utf-8" />
<title>Appointment software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

        <h1 className="pt-4 pb-4 text-center">Schedule a Free Discovery Call</h1>
        <InlineWidget url="https://calendly.com/quantumbases" />
    
    </div>
  );
};

export default Appointment;
