import React from "react";
import { Carousel } from "react-responsive-carousel";
import ServicesMore from "../aboutus/ServicesMore";
// import Link from "link-react";
import { Link } from "react-router-dom";


const Services = () => {
  return (
    <div>
      <section class="services-area-three">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7">
              <div class="section-title title-style-two text-center mb-45">
                <span class="sub-title">What We Do</span>
                <h2 class="title">
                  We Make <span>End-to-End</span> <br /> <Link to="/blogs">  IT Solutions Company </Link> 
                </h2>
                <p>
                  Quantum Bases Capital, a well-established IT solution provider
                  in Pakistan, offers a wide range of services
                </p>
              </div>
            </div>
          </div>
          {/* <div class="services-item-wrap">
            <div class="row services-active">
              <div class="col-lg-4">
                <div
                  class="services-item-three "
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <div class="services-icon-three">
                    <img src="assets/img/icon/h3_services_icon01.png" alt="" />
                  </div>
                  <div class="services-content-three">
                    <h3 class="title">
                      <a href="">Front-End Development</a>
                    </h3>
                    <p>
                      Quantum bases delivers the best UI/UX experience to the
                      customer with best use of programming language to build
                      and design the visual elements and interactivity of a
                      website.
                    </p>
                    <a href="" class="link-btn">
                      Learn More <i class="fal fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div
                  class="services-item-three "
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <div class="services-icon-three">
                    <img src="assets/img/icon/h3_services_icon02.png" alt="" />
                  </div>
                  <div class="services-content-three">
                    <h3 class="title">
                      <a href="">Blockchain Development</a>
                    </h3>
                    <p>
                      Quantum bases develops blockchain that underpins popular
                      cryptocurrencies like Bitcoin and Ethereum etc that
                      capture the attention of investors.
                    </p>
                    <a href="" class="link-btn">
                      Learn More <i class="fal fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div
                  class="services-item-three "
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <div class="services-icon-three">
                    <img src="assets/img/icon/h3_services_icon03.png" alt="" />
                  </div>
                  <div class="services-content-three">
                    <h3 class="title">
                      <a href="">Back-End Development</a>
                    </h3>
                    <p>
                      Quantum bases refers to the implementation and management
                      of the server-side logic, databases, and APIs that power a
                      website or application.
                    </p>
                    <a href="" class="link-btn">
                      Learn More <i class="fal fa-long-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <ServicesMore/>
        </div>
        <div class="services-shape">
          <img src="assets/img/images/h3_services_shape.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Services;
