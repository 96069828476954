import Carousel from "better-react-carousel";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Loader from "../../Loader";
import { constant } from "../../config/config";
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet";
const Blogsdetails = () => {
  const [blogData, setBlogData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [getTitle, setGetTitle] = useState();
  const [getDescription, setGetDescription] = useState();
  const [getTags, setGetTags] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pdata, setpdata] = useState()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const blogdetail = searchParams.get("blogId");
  const displayData = blogData.attributes?.display?.data;
  const imageUrl = displayData?.attributes?.url;

  const meta = {
    title: getTitle,
    description: getDescription,
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: getTags,
      },
    },
  };

  // console.log("Hye Bilal", blogData.attributes.display.data.attributes.url);

  useEffect(() => {
    if (blogdetail) {
      setIsLoading(true);
      try {
        const token =
          "ff0e86a1e60669c61efca944bc5370545f073a4a2feffcbea9ea99210c81ecce0280c044d131ce5a4f2e4ef367c632d97cc0947e0284aa30c24ab64080b44bf0254f8f3f35702345f627b459626b0ad42f3ae8c9db7d2353b1277cd449ba06ec6184ca9011a53d622216965a76da25b935a2a50c0ce9660b063d1daee435af8c";

        axios
          .get(`${constant.apiUrl}/api/blogs/${blogdetail}?populate=*`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setBlogData(response.data.data);
            setGetTitle(response.data.data.attributes.metatiltle);

            setGetDescription(response.data.data.attributes.metaDescription);
            setGetTags(response.data.data.attributes.metaTags);
            setIsLoading(false);
            console.log("Data testing vlogs", response.data.data);
            // console.log("Data testing vlogs", response.data.data.attributes.RichTextblock[0].children[0].text);
            setpdata(response.data.data.attributes.RichTextblock)

          });
      } catch (error) {
        console.log("e get", error);
      }
    }
  }, [blogdetail]);
console.log("meta titla:-:",getTitle);
console.log("meta description:-:",getDescription);
console.log("meta tags:-:",getTags);



  return (

    <DocumentMeta {...meta}>

      {isLoading && <Loader />}
      <div className="breadcrumb-area pt-175 pb-70 relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1
                className="text-center pt-10 pb-70"
                style={{ textAlign: "center" }}
              >
                {blogData?.attributes?.title}
              </h1>

              <div className="">
                <img
                  style={{
                    borderRadius: "30px",
                    width: "100%",
                    height: "700px",
                    objectFit: "cover",
                  }}
                  src={`${constant.apiUrl}${imageUrl}`}
                />
                {/* {blogData?.attributes?.display?.data ? (
                  <Carousel cols={1} rows={1} gap={10} autoPlay loop>
                    {images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          style={{ borderRadius: "30px", width: "100%", height:"700px", objectFit:"cover" }}
                          src={`${constant.apiUrl}${blogData.attributes.display.data.attributes.url}`}
                          alt={`Image ${index}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <p>No images available.</p>
                )} */}
              </div>
              <div className="row pt-80">
                <div className="col-lg-12">
                  <br />
                  <p style={{ textAlign: "justify" }}>
                    {blogData?.attributes?.description}
                  </p>
                  {/* <h1> This is Rich Text mark down</h1> */}
                  <ReactMarkdown style={{ textAlign: "justify" }}>
                    {blogData?.attributes?.RichTextmarkdown}
                  </ReactMarkdown>
                

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Blogsdetails;
// import Carousel from "better-react-carousel";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useParams, useLocation } from "react-router-dom";
// import DocumentMeta from "react-document-meta";
// import Loader from "../../Loader";
// import { constant } from "../../config/config";
// const Blogsdetails = () => {
//   const [blogData, setBlogData] = useState([]);
//   const [userData, setUserData] = useState([]);
//   const [getTitle, setGetTitle] = useState();
//   const [getDescription, setGetDescription] = useState();
//   const [getTags, setGetTags] = useState();

//   const [isLoading, setIsLoading] = useState(true);

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const blogdetail = searchParams.get("blogId");

//   const meta = {
//     title: getTitle,
//     description: getDescription,
//     canonical: "http://example.com/path/to/page",
//     meta: {
//       charset: "utf-8",
//       name: {
//         keywords: getTags,
//       },
//     },
//   };

//   // const { projectName } = useParams();
//   useEffect(() => {
//     // console.log(blogdetail);
//     if (blogdetail) {
//       // Add a check for projectName
//       setIsLoading(true);
//       try {
//         console.log("ptttt", blogdetail);

//         const token = "84f0ff29ef29e2988056b0cec1c8c9c1d04547841f9e53635fd01947ec3cc3cef29ac8748fcfe4f8b083e5a65fe97effc147ea828115076805bf8ace58fc4f1a93f8e4757941e06f67394bfe9b212c6992d1381a87e775ee199b32725c9f33cec1494c4c769883e35356b3c41553408b27997db1be2a190ac4c7d40bae8f04d4";

//         axios
//           .get(`${constant.apiUrl}/api/portfolios/${blogdetail}?populate=*`, {
//               headers: {
//                 Authorization: `Bearer ${token}`
//               }
//           })
//           .then((response) => {
//             // console.log("through id data", response.data.data.attributes.background.data.attributes.url);

//             setBlogData(response.data.data);
//             console.log(response.data.data);
//             // setUserData(response.data.data.id);
//             // setGetTitle(response.data.data.attributes.metaTitle);
//             // setGetDescription(response.data.data.attributes.metaDescription);
//             // setGetTags(response.data.data.attributes.metaTags);

//             setIsLoading(false);
//           });
//       } catch (error) {
//         console.log("e get", error);
//       }
//     }
//   }, [blogdetail]);

//   return (
//     <DocumentMeta {...meta}>
//       {isLoading && <Loader />}
//       <div className="breadcrumb-area pt-175 pb-70 relative">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-12">
//               <h1
//                 className="text-center pt-10 pb-70"
//                 style={{ textAlign: "center" }}
//               >
//                 {/* {userData?.title} */}
//                 {blogData?.attributes?.heading}
//               </h1>

//               <div class="">

//                  <img
//                   src={`${constant.apiUrl}${blogData?.attributes?.images?.data[0]?.attributes?.url}`}
//                   alt=""
//                 />
//               </div>
//               <div className="row pt-100">
//                 <div className="col-lg-12">
//                   <br />

//                   <p style={{ textAlign: "justify" }}>
//                     {/* {userData?.description} */}
//                     {blogData?.attributes?.description}

//                   </p>
//                 </div>
//                 {/* <div className="col-lg-4 ">
//                   <h3>Tools & Technologies</h3>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>ReactJS</h6>
//                   </div>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>NextJS</h6>
//                   </div>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>ReactJS</h6>
//                   </div>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>ExpressJS</h6>
//                   </div>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>NodeJS</h6>
//                   </div>
//                   <div className="d-flex align-items-center pt-10">
//                     <div
//                       style={{
//                         border: "1px solid #2b2e61",
//                         borderRadius: "50%",
//                         padding: "0px 6px",
//                         marginRight: "15px",
//                       }}
//                     >
//                       <i
//                         class="fa-solid fa-check"
//                         style={{ color: "#2b2e61" }}
//                       ></i>
//                     </div>
//                     <h6>MongoDB</h6>
//                   </div>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </DocumentMeta>
//   );
// };

// export default Blogsdetails;
