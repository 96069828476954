import React from "react";
import { Link } from "react-router-dom";
const ServicesMore = () => {
  return (
    <div>
      <section id="services" class="inner-services-area pt-40 pb-40">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div
                class="section-title title-style-two text-center mb-60"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                {/* <span class="sub-title">Graphic Service</span> */}
                {/* <h2 class="title">Services </h2> */}
              </div>
            </div>
          </div>
          <div class="inner-services-item-wrap">
            <div class="row justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/frontent-development">
                  <div
                    class="border_cards services-item-four  "
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon01.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">
                          Front-End Development
                        </a>
                      </h2>
                      <p>
                        Quantum bases delivers the best UI/UX experience to
                        customers with innovative, responsive, dynamic, and
                        intuitive by using all programming languages to build
                        and design the needs.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/backend-development">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon02.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">Back-End Development</a>
                      </h2>
                      <p>
                        Quantum bases refers to the implementation and
                        management of the server-side logics, databases, and
                        APIs that power a website or application efficiently,
                        securely, and scaleability.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/blockchain-development">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon03.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">
                          Blockchain Development
                        </a>
                      </h2>
                      <p>
                        Quantum bases develops blockchain technology that
                        underpins many popular cryptocurrencies such as Bitcoin,
                        Ethereum, attracting significant attention and
                        investment from investors.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/cloud-services">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon04.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">Cloud Services</a>
                      </h2>
                      <p>
                        A wide range of cloud services is provided to customers
                        that includes the customized servers, storage with
                        databases, networking as well software analytics,
                        intelligence, and more advanced solutions.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/UIIX">
                  <div
                    class=" border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon05.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">UI/UX </a>
                      </h2>
                      <p>
                        UI and UX designs play key roles in the product
                        development and lifecycle. Quantum Bases delivers a 100%
                        satisfactory UI/UX experience, ensuring users are
                        delighted and engaged with every interaction.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/SEO">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon06.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">SEO </a>
                      </h2>
                      <p>
                        Quantum Bases SEO methodology comprises the practices
                        designed to enhance all website's appearance or
                        positioning, and ranking on search engines, ultimately
                        driving more traffic and visibility.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/SMM">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon07.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">SMM</a>
                      </h2>
                      <p>
                        Social apps are powerful marketing tools enabling brands
                        to connect with audiences. Quantum bases selects the
                        best platforms to effectively promote and enhance that
                        brand's presence and engagement.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <Link to="/webdevelopment">
                  <div
                    class="border_cards services-item-four"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="services-icon-four">
                      <img
                        src="assets/img/icon/inner_services_icon08.png"
                        alt=""
                      />
                    </div>
                    <div class="services-content-four">
                      <h2 class="title">
                        <a href="services-details.html">Web Development</a>
                      </h2>
                      <p>
                        Quantum Bases is a great resource for aspiring web
                        developers that provides essential tools and insights to
                        create, build, and maintain website UI and UX interfaces
                        with excellence efficiency  and courage.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesMore;
