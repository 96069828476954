import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>Contact Us software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <main>
        {/* <!-- breadcrumb-area --> */}
        <section class="breadcrumb-area breadcrumb-area-four pt-175 pb-70">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="breadcrumb-content">
                  <h2 class="title">Contact Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Contact
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="breadcrumb-shape-wrap-three">
            <img
              src="assets/img/images/breadcrumb_shape04.png"
              alt=""
              class="wow zoomIn"
              data-wow-delay=".2s"
            />
            <img
              src="assets/img/images/breadcrumb_shape05.png"
              alt=""
              class="wow zoomIn"
              data-wow-delay=".2s"
            />
            <img
              src="assets/img/images/breadcrumb_shape06.png"
              alt=""
              class="wow zoomIn"
              data-wow-delay=".2s"
            />
          </div>
        </section>
        {/* <!-- breadcrumb-area-end -->

<!-- contact-area --> */}
        <section class="inner-contact-area">
          <div class="container">
            <div
              class="inner-contact-wrap"
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  <div class="section-title title-style-two mb-50">
                    <h2 class="title">
                      Have a <span>Cool Project?</span> Get in touch!
                    </h2>
                  </div>
                  <div class="inner-contact-form-wrap">
                    <div className="row">
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <h1>How We can Help You ?</h1>
                        <p style={{ textAlign: "justify" }}>
                          Our dedicated team is here to provide comprehensive
                          solutions tailored to your specific needs. From web
                          development and design to digital marketing
                          strategies, we are committed to delivering exceptional
                          results that drive your success. Let us be your
                          trusted partner in achieving your business goals.
                        </p>
                        <div class="inner-contact-info pb-80 pt-80">
                          <ul class="list-wrap">
                            <li>
                              <div class="contact-info-item">
                                <div class="icon">
                                  <img
                                    src="assets/img/icon/loction_icon03.png"
                                    alt=""
                                  />
                                </div>
                                <div class="content">
                                  <h6 class="title">Address</h6>
                                 <p>
                                  <i
                                    className="fa-solid fa-location-dot"
                                    // className="fa-solid fa-location-pin"
                                    style={{
                                      color: "#000000",
                                      marginRight: "10px",
                                    }}
                                  ></i>
                                  
                                    30 N Gould St Ste 27376 Sheridan, WY82801
                                  </p>
                                  <p><i
                                    className="fa-solid fa-location-dot"
                                    // className="fa-solid fa-location-pin"
                                    style={{
                                      color: "#000000",
                                      marginRight: "10px",
                                    }}
                                  ></i>328L Johar town, Lahore, Pakistan</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="contact-info-item">
                                <div class="icon">
                                  <img
                                    src="assets/img/icon/mail_icon03.png"
                                    alt=""
                                  />
                                </div>
                                <div class="content">
                                  <h6 class="title">Email</h6>
                                  <a href="mailto:your@email.com">
                                    Contact@QuantumBases.com
                                  </a>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="contact-info-item">
                                <div class="icon">
                                  <img
                                    src="assets/img/icon/phone_icon03.png"
                                    alt=""
                                  />
                                </div>
                                <div class="content">
                                  <h6 class="title">Phone</h6>
                                  <a href="tel:+1(346)201-6130">
                                    {" "}
                                    +1(346)201-6130
                                  </a>
                                  <a href="tel:+92 42 37870328">
                                    +92 42 37870328
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <p>
                          You can directly Contact with us{" "}
                          <a href="mailto:contact@quantumbases.com">
                            contact@quantumbases.com
                          </a>
                        </p>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <form action="#">
                          <div class="form-grp">
                            <label for="name">
                              <i class="fas fa-user"></i>
                            </label>
                            <input type="text" id="name" placeholder="Name" />
                          </div>
                          <div class="form-grp">
                            <label for="phone">
                              <i class="fas fa-phone"></i>
                            </label>
                            <input type="text" id="phone" placeholder="Phone" />
                          </div>
                          <div class="form-grp">
                            <label for="email">
                              <i class="fas fa-envelope"></i>
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="Email Address"
                            />
                          </div>
                          <div class="form-grp">
                            <label for="subject">
                              <i class="fas fa-book-alt"></i>
                            </label>
                            <input
                              type="text"
                              id="subject"
                              placeholder="Subject"
                            />
                          </div>
                          <div class="form-grp">
                            <label for="comment">
                              <i class="fas fa-user-edit"></i>
                            </label>
                            <textarea
                              name="comment"
                              id="comment"
                              placeholder="How can we help you? Feel free to get in touch!"
                            ></textarea>
                          </div>
                          <button type="submit" class="btn">
                            Send Message <span></span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <div id="contact-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96811.54759587669!2d-74.01263924803828!3d40.6880494567041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2sbd!4v1636195194646!5m2!1sen!2sbd"
                      allowfullscreen
                      loading="lazy"
                    ></iframe>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- contact-area-end --> */}
      </main>
    </div>
  );
};

export default ContactUs;
