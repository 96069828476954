import React from "react";

import Carousel from "better-react-carousel";
const Testimonial = () => {
  return (
    <div>
      <section class="testimonial-area-three">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8">
              <div class="section-title title-style-two white-title mb-45">
                <h2 class="title">
                  Some Words From <br /> Our Customers
                </h2>
              </div>
            </div>
            {/* <div class="col-md-4">
              <div class="testimonial-nav">
                <button class="swiper-button-prev"></button>
                <button class="swiper-button-next"></button>
              </div>
            </div> */}
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="swiper-container testimonial-active-three">
                <div class="swiper-wrapper">
                  <Carousel cols={2} rows={1} gap={10} loop={true} autoplay={3000} >
                    {/* <div className="container">
                      <div className="row"> */}
                    <Carousel.Item>
                      <div
                        class="swiper-slide "
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <div class="testimonial-item-three">
                          <div class="testimonial-thumb-three">
                            <img src="assets/img/logo/syal.jpeg" alt="" />
                          </div>
                          <div class="testimonial-content-three">
                            <div className="d-flex justify-content-between">
                              <h4 class="title">Eyal Schiffer</h4>
                              <span
                                style={{
                                  border: "1px solid #f89e52",
                                  padding: "4px 10px 4px",
                                  borderRadius: "7px",
                                  margin: "0px",
                                }}
                              >
                                <a href="https://www.linkedin.com/in/eyalschiffer/">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                              </span>
                            </div>
                            <span>Founder | Bright Media Solutions Ltd. </span>
                            <p>
                              “Quantum Bases (QB) is an outstanding company,
                              providing invaluable contributions to our platform
                              development, and we look forward to engaging them
                              for future consulting and improvements.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        class="swiper-slide "
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <div class="testimonial-item-three">
                          <div class="testimonial-thumb-three">
                            <img src="assets/img/logo/julien.jpeg" alt="" />
                          </div>
                          <div class="testimonial-content-three">
                            <div className="d-flex justify-content-between">
                              <h4 class="title">Julian Benltran</h4>
                              <span
                                style={{
                                  border: "1px solid #f89e52",
                                  padding: "4px 10px 4px",
                                  borderRadius: "7px",
                                  margin: "0px",
                                }}
                              >
                                <a href="https://www.linkedin.com/in/julianmb/">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                              </span>
                            </div>
                            <span>Founder | DAST AI LLC.</span>
                            <p>
                              “ I had the pleasure of collaborating with a
                              company that demonstrated exceptional knowledge
                              and expertise in blockchain development, leading
                              to successful outcomes for our project. ”
                            </p>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        class="swiper-slide "
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                        <div class="testimonial-item-three">
                          <div class="testimonial-thumb-three">
                            <img src="assets/img/logo/layuman.jpeg" alt="" />
                          </div>
                          <div class="testimonial-content-three">
                            <div className="d-flex justify-content-between">
                              <h4 class="title">Luay Mohsen</h4>
                              <span
                                style={{
                                  border: "1px solid #f89e52",
                                  padding: "4px 10px 4px",
                                  borderRadius: "7px",
                                  margin: "0px",
                                }}
                              >
                                <a href="https://www.linkedin.com/in/luay-mohsen-01265a12/">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                              </span>
                            </div>
                            <span>CTO | Nugenesis spell</span>
                            <p>
                              “QB is an excellent company with exceptional
                              communication skills, capable of explaining
                              complex technical concepts in a simple and
                              understandable manner. They are highly efficient and
                              detail-oriented
                               {/* and ensure meticulous execution
                              of every aspect of the project to meet the
                              client's expectations.” */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        class="swiper-slide "
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <div class="testimonial-item-three">
                          <div class="testimonial-thumb-three">
                            <img src="assets/img/logo/luke.jpeg" alt="" />
                          </div>
                          <div class="testimonial-content-three">
                            <div className="d-flex justify-content-between">
                              <h4 class="title">Luke Tannous</h4>
                              <span
                                style={{
                                  border: "1px solid #f89e52",
                                  padding: "4px 10px 4px",
                                  borderRadius: "7px",
                                  margin: "0px",
                                }}
                              >
                                <a href="https://www.linkedin.com/in/luke-tannous-a1821267/">
                                  <i class="fab fa-linkedin-in"></i>
                                </a>
                              </span>
                            </div>{" "}
                            <span>Founder | luketannous.com</span>
                            <p>
                              “ QB, as a company, played a pivotal role in the
                              development of a smart contract for QWAN. Their
                              guidance and expertise were instrumental,
                              providing valuable recommendations for improving
                              the smart contract development process. ”
                            </p>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    {/* </div>
                    </div> */}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-swiper-pagination"></div>
        </div>
        <div class="testimonial-shape-wrap">
          <img src="assets/img/images/testimonial_shape01.png" alt="" />
          <img src="assets/img/images/testimonial_shape02.png" alt="" />
          <img src="assets/img/images/testimonial_shape03.png" alt="" />
          <img src="assets/img/images/testimonial_shape04.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
