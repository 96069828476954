import React from "react";
import { Link } from "react-router-dom";

const CompnayArea = () => {
  return (
    <div>
      <section class="agency-area pt-20">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div
                class="agency-img text-center "
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <img src="assets/img/images/Agency.png" alt="" />
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="agency-content "
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <div class="section-title title-style-two mb-20">
                  <span class="sub-title">Our Agency</span>
                  <h3 class="title">QUANTUM BASES LAB</h3>
                </div>
                <p class="info-one">
                  Quantum Bases Labs is the Lahore-based subsidiary of Quantum
                  Bases Capital, focusing on providing a wide range of
                  customized software development services such as product
                  development, software engineering, content creation, and
                  digital marketing support. Quantum Bases Lab will enable both
                  current and future projects to prosper in the competitive
                  market
                </p>

                <Link to="/about-us" class="p-btn" style={{ color: "white" }}>
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="agency-shape-wrap">
          <img src="assets/img/images/agency_shape01.png" alt="" />
          <img src="assets/img/images/agency_shape02.png" alt="" />
          <img src="assets/img/images/agency_shape03.png" alt="" />
          <img src="assets/img/images/agency_shape04.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default CompnayArea;
