import React from "react";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
const FrontentDevelopment = () => {
  const myStyle = {
    width: "100%",
    height: "100%",
    transform: "translate3d(0px, 0px, 0px)",
  };
  return (
    <div className="breadcrum-area details pt-175 pb-70">
        <Helmet>
                <meta charSet="utf-8" />
                <title>Frontend Development software development services </title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="pt-5 ">
              <h1 className="title">Frontend Development</h1>
              <p>
                Our Front-end development services refer to the set of
                activities involved in creating the user interface (UI) and user
                experience (UX) of a website or web application. Front-end
                developers are responsible for implementing the visual and
                interactive elements that users interact with directly. We
                provide the best front-end development services to create
                user-friendly and visually appealing websites and web
                applications. Our Front-end development services play a
                significant role in shaping the user experience and can make a
                huge difference in how users interact with your digital
                products.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <h1 className="title text-center pt-5 pb-5">Our Process</h1>
          <div className="col-lg-6">
            <img src="/assets/img/images/process.svg" alt="" />
          </div>
          <div className="col-lg-6">
            <div className="pt-40">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#f89e52",
                    padding: "10px 15px",
                    borderRadius: "50%",
                    marginRight: "20px",
                    position: "relative",
                    top: "-60px",
                  }}
                >
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style={{ color: "#ffffff" }}
                  ></i>
                </div>
                <div>
                  <h4>Project Planning and Design</h4>
                  <p>
                    First, we understand the project's goals and visualize the
                    layout, user interface, and user experience (UI/UX) design.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#f89e52",
                    padding: "10px 15px",
                    borderRadius: "50%",
                    marginRight: "20px",
                    position: "relative",
                    top: "-60px",
                  }}
                >
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style={{ color: "#ffffff" }}
                  ></i>
                </div>
                <div>
                  <h4>Testing and Deployment</h4>
                  <p>
                    Our developer starts by creating the basic HTML structure of
                    the web pages based on the approved wireframes and implement
                    interactive elements, animations, and dynamic features using
                    JavaScript.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#f89e52",
                    padding: "10px 15px",
                    borderRadius: "50%",
                    marginRight: "20px",
                    position: "relative",
                    top: "-60px",
                  }}
                >
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style={{ color: "#ffffff" }}
                  ></i>
                </div>
                <div>
                  <h4>Testing and Deployment</h4>
                  <p>
                    At last, we conduct usability testing with real users to
                    gather feedback and identify areas of improvement in the
                    user interface and user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <h1 className="text-center pt-5 pb-5">
            FrontEnd Development Services
          </h1>
          <div className=" col-lg-4">
            <div
              className="border_cards p-4 mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" img_size d-flex justify-content-center"
                  src="/assets/img/images/ux.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">UI/UX Design</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                Work with web designers to create wireframes, mockups, and
                visual designs for the user interface. Collaborate closely to
                finalize the design, ensuring it aligns with the project's
                objectives, providing a cohesive and user-friendly experience
                that enhances the user engagement, accessibility, and
                satisfaction with design.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className=" border_cards p-4  mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className="d-flex justify-content-center"
                  src="/assets/img/images/html.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">HTML/CSS Markup</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                Start converting the approved design into HTML and CSS code. Use
                HTML to structure the content, defining elements like headers,
                paragraphs, and images. Utilize CSS for styling the layout,
                colors, typography, and other visual elements, ensuring the
                design looks polished and professional on all devices and
                browsers
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="border_cards p-4  mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" img_size d-flex justify-content-center"
                  src="/assets/img/images/client.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">Client-Side Scripting</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                To implement interactivity and dynamic features using JavaScript
                or a front-end framework like React, Angular, or Vue.js, develop
                functionalities such as form validation, animations, sliders,
                and responsive navigation menus. These features enhance user
                experience and provide a more engaging and intuitive 
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="border_cards p-4  mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" img_size d-flex justify-content-center"
                  src="/assets/img/images/responsive.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">Responsiveness</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                Test the website or application on various devices (Desktop,
                Tablet, Mobile) to ensure responsiveness across different screen
                sizes. Check for compatibility and usability across different
                web browsers (Chrome, Firefox, Safari, Edge) and address any
                issues thoroughly, including layout discrepancies and
                functionalities .{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="border_cards p-4  mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" img_size d-flex justify-content-center"
                  src="/assets/img/images/perform.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">Performance Optimization</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                Developers optimize the code, compress images using lossless
                techniques, and minify CSS and JavaScript to improve loading
                times significantly. They also utilize caching mechanisms,
                leverage Content Delivery Networks (CDNs), and implement lazy
                loading for further performance and enhancements.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="border_cards p-4  mb-4 details-services"
              style={{
                border: "1px solid #dfdfdf",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className=" img_size d-flex justify-content-center"
                  src="/assets/img/images/deploy.png"
                  alt=""
                />
              </div>
              <h4 className="pt-4 pb-4">Deployment</h4>
              <p className="text-justify" style={{ textAlign: "justify" }}>
                We prepare the project for deployment on a web server or hosting
                platform by ensuring that all the necessary files, configurations,
                dependencies, and permissions are in place for a smooth and
                successful launch.We also conduct thorough testing to ensure
                functionality and increase performance to security.
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: "90px 0" }}>
          <div className="col-lg-6">
            <h2 style={{ fontSize: "40px" }}>
              Let’s build a product that gets results!
            </h2>
            <p style={{ textAlign: "justify" }}>
              Quantum Bases openly collaborates with ambitious startups and
              companies to plan, build, and grow tech products. Our partnership
              goes far beyond delivering features; we make sure we solve the
              right problem and build a product that brings true value to users.
            </p>
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: " white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: "white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>Consulting Service</h6>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: "white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: " white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>API Integrations</h6>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: " white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>End to End Support</h6>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      background: "#1f6eff",
                      padding: "15px 20px",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ color: " white", marginRight: "20px" }}
                    ></i>
                    <h6 style={{ color: "white" }}>Prototyping Expertise</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="/assets/img/images/points.svg" alt="" />
          </div>
        </div>
        {/* <div className="row" style={{ padding: "90px 0" }}>
          <h2 className="pt-5 pb-5 text-center">
            Types of Front end development services we offer:
          </h2>
          <div className="col-lg-6">
            <h4>Responsive Web Design: </h4>
            <p style={{textAlign:"justify"}}>
              Front-end developers specialize in creating websites and web
              applications that adapt and display properly on various devices
              and screen sizes, ensuring a seamless user experience across
              desktops, tablets, and mobile phones.
            </p>
          </div>
          <div className="col-lg-6">
            <h4>Single-Page Applications (SPAs): </h4>
            <p style={{textAlign:"justify"}}>
              Front-end developers create SPAs with popular frameworks such as
              React, Angular, and Vue.js. SPAs use a single HTML page to load
              content and dynamically update it when users interact with the
              application, resulting in a quicker and more fluid user
              experience.
            </p>
          </div>
          
        </div> */}
      </div>
    </div>
  );
};

export default FrontentDevelopment;
