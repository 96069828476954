import React from "react";
import { Helmet } from "react-helmet";

const CloudServices = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>Cloud Services software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <div className="breadcrum-area details pt-175 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-5 ">
                <h1 className="title">Cloud Services</h1>
                <p>
                  Cloud services act like a rented space on the internet,
                  offering storage and management solutions. Instead of clogging
                  your own device, you harness remote, robust servers. This
                  allows data access from anywhere, resembling a virtual
                  storeroom ready at your convenience. It's an efficient way to
                  offload digital baggage and access resources seamlessly. Just
                  as you'd rent storage for physical items, cloud services
                  provide a virtual vault for your digital assets. This
                  scalable, on-demand solution has become a cornerstone of
                  modern data management.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <h1 className="title text-center pt-5 pb-5">Our Process</h1>
            <div className="col-lg-6">
              <img src="/assets/img/images/process.svg" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="pt-40">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Data Storage and Management</h4>
                    <p>
                      In this step, your files and data are stored on big and
                      powerful computers that are far away but connected to the
                      internet. These computers, called servers, keep your
                      information safe and organized, making it easy for you to
                      access and manage your stuff from anywhere.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Accessing and Using Services</h4>
                    <p>
                      Once your data is in the cloud, you can use various
                      services like email, apps, or even processing power
                      without needing a powerful computer of your own. You can
                      access these services through the internet, like how you
                      visit websites.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Security and Backup </h4>
                    <p>
                      Cloud service providers take steps to keep your
                      information safe. They use special locks, like passwords
                      and encryption, to protect your data. Additionally, they
                      often create copies of your data in different places to
                      make sure it's not lost even if something goes wrong.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h1 className="text-center pt-5 pb-5">
              Cloud Deployment Services:
            </h1>
            <div className="col-lg-4">
              <div
                className="border_cards p-4 mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/ux.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Data Storage</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Cloud services provide a place on the internet to keep your
                  files and data, so you don't need to worry about running out
                  of space on your device. They offer convenient access from
                  anywhere, automatic backups, robust security measures, and
                  scalable storage options to accommodate your growing needs.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards  p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/html.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Software as a Service </h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  This service lets you use programs and apps directly from the
                  cloud without downloading or installing them on your computer.
                  It streamlines your workflow, reduces storage clutter, ensures
                  seamless access to the latest versions of software, and
                  enhances collaboration effortlessly.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards  p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/client.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Infrastructure as a Service</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Before you use it, we test everything to fix any problems,
                  ensuring your experience is hassle-free and enjoyable. Our
                  rigorous testing process covers all aspects, guaranteeing
                  top-notch performance, reliability, and user satisfaction,
                  ultimately providing peace of mind and seamless usage.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards  p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/responsive.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Backup and Recovery </h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Cloud services can automatically make copies of your important
                  files so that if something happens to your device, you can
                  still get your data back. This redundancy ensures data
                  security and continuity, giving you peace of mind knowing your
                  information is always accessible and protected.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards  p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/perform.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Scalability</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Cloud services let you easily expand or shrink the resources
                  you're using, like storage space or processing power,
                  depending on your needs. This scalability ensures optimal
                  utilization of resources, cost-effectiveness, and flexibility,
                  allowing you to adapt swiftly to changing demands and maximize
                  efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards  p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/deploy.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Collaboration Tools</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Cloud services offer tools that allow people to work together
                  on the same files and projects, no matter where they are,
                  making teamwork easier. These collaborative features
                  streamline communication, enhance productivity, and foster
                  creativity among team members
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "90px 0" }}>
            <div className="col-lg-6">
              <h2 style={{ fontSize: "40px" }}>
                Let’s build a product that gets results!
              </h2>
              <p style={{ textAlign: "justify" }}>
                To achieve better outcomes, we aim for improved cloud services.
                By enhancing the way we use online storage and tools, we can
                reach higher levels of efficiency and success.
              </p>
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Consulting Service</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>API Integrations</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>End to End Support</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Prototyping Expertise</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/points.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudServices;
