import React from "react";
import ReactDOM from "react-dom/client";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Helmet } from "react-helmet";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
const GoogleAnalyticsScript = () => (
  <React.Fragment>
    {/* Google tag (gtag.js) */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-01X8KDHEF4"
    ></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-01X8KDHEF4');
      `}
      
    </script>
  </React.Fragment>
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <GoogleAnalyticsScript />
    </Helmet>
    <App />
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
