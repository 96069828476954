import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

const CounterArea = () => {
  return (
    <div>
      <section class="counter-area-two pt-30 pb-30">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 order-0 order-lg-2"
              data-aos="fade-up"
              data-aos-duration="1400"
            >
              <div class="counter-item-wrap-two ">
                <ul class="list-wrap">
                  <li>
                    <div class="counter-item-two">
                      <div class="icon">
                        <img src="assets/img/icon/counter_icon01.png" alt="" />
                      </div>
                      <div class="content">
                        <span class="count odometer" data-count="252">
                          <CountUp end={2} />
                        </span>
                        <p>Offices</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="counter-item-two">
                      <div class="icon">
                        <img src="assets/img/icon/counter_icon02.png" alt="" />
                      </div>
                      <div class="content">
                        <span class="count odometer" data-count="10">
                          <CountUp end={20} />
                        </span>
                        <p>Workers</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="counter-item-two">
                      <div class="icon">
                        <img src="assets/img/icon/counter_icon03.png" alt="" />
                      </div>
                      <div class="content">
                        <h2 class="count">
                          <span class="odometer" data-count="15">
                            <CountUp end={152} />
                          </span>
                          +
                        </h2>
                        <p>Customers</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="counter-item-two">
                      <div class="icon">
                        <img src="assets/img/icon/counter_icon04.png" alt="" />
                      </div>
                      <div class="content">
                        <h2 class="count">
                          <span class="odometer" data-count="1300">
                            <CountUp end={200} />
                          </span>
                          +
                        </h2>
                        <p>Projects</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="counter-content "
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <div class="section-title title-style-two mb-20">
                  <span class="sub-title">Our Success</span>
                  <h2 class="title">
                    Over 200+ Completed Work & Still Counting.
                  </h2>
                </div>
                <p class="info">
                  Over 200+ completed projects and counting, showcasing our
                  company's proven track record of delivering successful
                  outcomes. Our commitment to excellence drives ongoing growth
                  and client satisfaction.
                </p>
                {/* <Link to="/portfolio" class="p-btn" style={{ color: "white" }}>
                  Learn More 
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CounterArea;
