import React from "react";

const Updates = () => {
  return (
    <div>
      <section
        class="newsletter-area-two"
        style={{ marginTop: "65px", marginBottom: "25px" }}
      >
        <div class="container">
          <div class="newsletter-wrap">
            <div class="row justify-content-center">
              <div class="col-xl-7 col-lg-9">
                <div
                  class="section-title title-style-two white-title text-center mb-30 "
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <span class="sub-title">Get update</span>
                  <h2 class="title">Get latest updates and deals</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-8">
                <div
                  class="newsletter-form "
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <form action="#">
                    <input
                      type="email"
                      placeholder="Enter your email address"
                    />
                    <button type="submit" class="btn">
                      Subscribe <span></span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="newsletter-shape-wrap-two">
              <img src="assets/img/images/h3_newsletter_shape01.png" alt="" />
              <img src="assets/img/images/h3_newsletter_shape02.png" alt="" />
              <img src="assets/img/images/h3_newsletter_shape03.png" alt="" />
              <img src="assets/img/images/h3_newsletter_shape04.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Updates;
