import React from 'react';

const Teams = () => {
  return (
    <div>
      <section class="team-area-two pt-70 pb-40">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-7">
                <div
                  class="section-title title-style-two text-center mb-70"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <h2 class="title">Meet The Squad</h2>
                  <p>
                    A talented and dedicated team of professionals who bring
                    diverse expertise, creativity, and passion to deliver
                    outstanding results, driving our company's success and
                    exceeding client expectations.
                  </p>
                </div>
              </div>
            </div>
            <div class="row team-active">
              <div class="col-lg-4">
                <div class="team-item">
                  <div
                    class="team-thumb"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <a href="https://www.linkedin.com/in/hamzayasin/">
                      <img src="assets/img/logo/hamza.jpeg" alt="" />
                    </a>
                  </div>
                  <div class="team-content">
                    <h2 class="title">
                      <a href="https://www.linkedin.com/in/hamzayasin/">Hamza Yasin</a>
                    </h2>
                    <span>CEO | Quantum Bases</span>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="team-item">
                  <div
                    class="team-thumb"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <a href="https://www.linkedin.com/in/zainmustafaaaa/">
                      <img src="assets/img/logo/zain.jpeg" alt="" style={{width:"100%"}} />
                    </a>
                  </div>
                  <div class="team-content">
                    <h2 class="title">
                      <a href="https://www.linkedin.com/in/zainmustafaaaa/">Zain Ul Abedin</a>
                    </h2>
                    <span>CTO | Quantum Bases</span>
                   
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="team-item">
                  <div
                    class="team-thumb"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <a href="https://www.linkedin.com/in/fahad-aziz-4b696513a/">
                      <img src="assets/img/logo/fahad.jpg" alt="" />
                    </a>
                  </div>
                  <div class="team-content">
                    <h2 class="title">
                      <a href="https://www.linkedin.com/in/fahad-aziz-4b696513a/">Fahad Aziz</a>
                    </h2>
                    <span>Co-Founder | Quantum Bases</span>
                    
                  </div>
                </div>
              </div>
              
         
            </div>
          </div>
        </section>
    </div>
  );
}

export default Teams;
