import React from 'react';
import Header from './Header';
import HeaderContent from './HeaderContent';
import Services from './Services';
import Brand from './Brand';
import CompnayArea from './CompnayArea';
import CounterArea from './CounterArea';

import Testimonial from './Testimonial';
import Updates from './Updates';
import Footer from './Footer';
import Schedule from '../Schedule';
import { Helmet } from 'react-helmet';
const Landing = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>software development services </title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HeaderContent />
      <Services />
      <Brand />
      <CompnayArea />
      <CounterArea />
      <Schedule />
      <Testimonial />
      <Updates />

    </div>
  );
}

export default Landing;
