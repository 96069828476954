import Carousel from "better-react-carousel";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import { Helmet } from "react-helmet";
import Loader from "../../Loader";
import { constant } from "../../config/config";

const Porfoliodetails = () => {


  const [blogData, setBlogData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [getTitle, setGetTitle] = useState();
  const [getDescription, setGetDescription] = useState();
  const [getTags, setGetTags] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const blogdetail = searchParams.get("portfolioId");

  const meta = {
    title: getTitle,
    description: getDescription,
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: getTags,
      },
    },
  };


  useEffect(() => {
    if (blogdetail) {
      setIsLoading(true);
      try {
        const token = "ff0e86a1e60669c61efca944bc5370545f073a4a2feffcbea9ea99210c81ecce0280c044d131ce5a4f2e4ef367c632d97cc0947e0284aa30c24ab64080b44bf0254f8f3f35702345f627b459626b0ad42f3ae8c9db7d2353b1277cd449ba06ec6184ca9011a53d622216965a76da25b935a2a50c0ce9660b063d1daee435af8c";

        axios
          .get(`${constant.apiUrl}/api/portfolios/${blogdetail}?populate=*`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then((response) => {
            setBlogData(response.data.data);
            setGetTitle(response.data.data.attributes.metaTitle);
            setGetDescription(response.data.data.attributes.metaDescription);
            setGetTags(response.data.data.attributes.metaTags);
            setIsLoading(false);
          });
      } catch (error) {
        console.log("e get", error);
      }
    }
  }, [blogdetail]);
  return (
    <>
<Helmet>
<meta charSet="utf-8" />
<title>Portfolio Details software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <DocumentMeta {...meta}>
      {isLoading && <Loader />}
      <div className="breadcrumb-area pt-175 pb-70 relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="text-center pt-10 pb-70" style={{ textAlign: "center" }}>
                {blogData?.attributes?.heading}
              </h1>

              <div className="">
                {blogData?.attributes?.images?.data ? (
                  <Carousel cols={1} rows={1} gap={10} autoPlay loop>
                    {blogData.attributes.images.data.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          style={{ borderRadius: "30px", width: "100%" }}
                          src={`${constant.apiUrl}${image.attributes.url}`}
                          alt={`Image ${index}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <p>No images available.</p>
                )}
              </div>
              <div className="row pt-100">
                <div className="col-lg-12">
                  <br />
                  <p style={{ textAlign: "justify" }}>
                    {blogData?.attributes?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
    </>
  );
};

export default Porfoliodetails;
