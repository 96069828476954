import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [year, setYear] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear);
  }, []);
  return (
    <div>
      <footer>
        <div class="footer-area mt-50">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <div
                    class="footer-widget "
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <div class="logo">
                      <Link to="/">
                        <img src="assets/img/logo/qb-logo-white.png" alt="" />
                      </Link>
                    </div>

                    {/* <div class="footer-contact">
                      <span>For Support</span>
                      <h2 class="title">
                        <a href="tel:0123456789">04237870328</a>
                      </h2>
                    </div> */}
                    {/* removed justify from here ------------------------------------ */}
                    <p style={{ textAlign: "", width: " 82%" }}>
                      Quantum Bases Capital, A Well-Established IT Solution
                      Provider In US AND Pakistan, Offers A Wide Range Of ,
                      professional services, and social media services.
                    </p>
                  </div>
                </div>
                {/* <div class="col-lg-3 col-sm-6">
                  <div
                    class="footer-widget "
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <h4 class="fw-title">Primary Pages</h4>
                    <div class="fw-link">
                      <ul class="list-wrap">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about-us">About</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact</Link>
                        </li>
                        <li>
                          <Link to="/about-us?route=services">Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div class="col-lg-4 col-sm-6 ">
                  <div
                    class="footer-widget "
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <h4 class="fw-title">Company</h4>
                    <div class="fw-link">
                      <ul class="list-wrap">
                        <li>
                          <Link to="/about-us">About</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Pricing</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Request for Demo</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                  <div
                    class="footer-widget "
                    data-aos="fade-up"
                    data-aos-duration="1400"
                  >
                    <h4 class="fw-title">Contact</h4>
                    <div class="footer-about">
                      <ul class="list-wrap">
                        <li>
                          <a href="tel:+1 (346) 201-6130">+1 (346) 201-6130 </a>
                        </li>

                        <li className="footer_data">
                          <a href="tel:+92 42 37870328">+92 42 37870328</a>
                        </li>
                        <li>
                          <a href="mailto:company@gmail.com">
                            Contact@QuantumBases.com
                          </a>
                        </li>
                        <li>
                          <span>
                            <i
                              className="fa-solid fa-location-dot"
                              // className="fa-solid fa-location-pin"
                              style={{ color: "#ffffff", marginRight: "10px" }}
                            ></i>
                            30 N Gloud St Ste 27376 Seridan, WY 82801 <br />{" "}
                            <i
                              className="fa-solid fa-location-dot"
                              style={{ color: "#ffffff", marginRight: "10px" }}
                            ></i>
                            328L Johar town, Lahore, Pakistan
                          </span>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-12">
                  <div class="copyright-text text-center">
                    <p>© {year} QuantumBases. All Rights Reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
