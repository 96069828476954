import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
const Header = () => {
  const [isopen, setIsOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsOpen(!isopen);
  };

  const toggleClosebtn = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <header>
        <div id="sticky-header" class="menu-area transparent-header">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <i class="fas fa-bars"></i>
                </div>
                <div class="menu-wrap">
                  <nav class="menu-nav">
                    <div class="logo">
                      <Link to="/">
                        <img src="assets/img/logo/QB-Bold.png" alt="Logo" />
                      </Link>
                    </div>
                    <div class="navbar-wrap main-menu d-none d-lg-flex">
                      <ul class="navigation">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about-us">About Us</Link>
                        </li>
                        <li>
                          <Link to="/portfolio">Case Studies</Link>
                        </li>

                        <li class=" menu-item-has-children">
                          <Link to="/frontent-development">Services</Link>
                          <ul class="sub-menu">
                            <li class="">
                              <Link to="/frontent-development">
                                Frontent Development
                              </Link>
                            </li>
                            <li class="">
                              <Link to="/backend-development">
                                Backend Development
                              </Link>
                            </li>
                            <li>
                              <Link to="/blockchain-development">
                                Blockchain Development
                              </Link>
                            </li>
                            <li>
                              <Link to="/webdevelopment">Web Development</Link>
                            </li>
                            <li>
                              <Link to="/cloud-services">Cloud Service</Link>
                            </li>
                            <li>
                              <Link to="/UIUX">UI/UX Service</Link>
                            </li>
                            <li>
                              <Link to="/SEO">SEO Service</Link>
                            </li>
                            <li>
                              <Link to="/SMM">SMM Service</Link>
                            </li>
                          </ul>
                          <div class="dropdown-btn">
                            <span class="fas fa-angle-down"></span>
                          </div>
                        </li>
                        <li>
                          <Link to="/blogs">Blogs</Link>
                        </li>
                        <li class="">
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        {/* <li
                          style={{
                            border: "1px solid #f89e52",
                            padding: "4px 10px 4px",
                            borderRadius: "7px",
                            margin: "0px",
                          }}
                        >
                          <Link to="https://www.linkedin.com/company/quantum-bases/">
                            <i class="fab fa-linkedin-in"></i>
                          </Link>
                        </li> */}
                        <li>
                          <Link to={"/appointment"} exact mode="dark">
                            <button
                              style={{
                                border: "2px solid #f89e52",
                                display: "block",
                                margin: "0 auto",
                              }}
                              // onClick={handleDownload}
                              className="nav-link Book fsnEF"
                            >
                              Free Strategy Call{" "}
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <Link to="/appointment">
                      <button className="b-btn ml-5">
                        Free Strategy call{" "}
                      </button>
                    </Link> */}
                    {/* ////////////////////////////////////////////////////////////////////////////// */}
                    {/* <Link to={"/appointment"} exact mode="dark">
                      <button
                        style={{
                          border: "2px solid #f89e52",
                          display: "block",
                          margin: "0 auto",
                        }}
                        // onClick={handleDownload}
                        className="nav-link Book fsnEF"
                      >
                        Free Strategy Call{" "}
                      </button>
                    </Link> */}

                    {/* <a
                      href="https://www.linkedin.com/company/quantum-bases/"
                      target={"_blank"}
                      className="d-lg-block d-none"
                      style={{
                        border: "1px solid #f89e52",
                        padding: "4px 10px 4px",
                        borderRadius: "7px",
                        margin: "0px",
                      }}
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a> */}

                    {/* <div class="header-action">
                      <ul class="list-wrap">
                        <li class="header-btn">
                          <a href="contactus" class="btn">
                            Contact <span></span>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </nav>
                </div>

                {/* <!-- Mobile Menu  --> */}
                {isopen && (
                  <div className="mobile-menu d-lg-none">
                    <nav class="menu-box">
                      <div class="close-btn" onClick={toggleClosebtn}>
                        <i class="fas fa-times"></i>
                      </div>
                      <div class="nav-logo">
                        <Link to="/">
                          <img src="assets/img/logo/QB-Bold.png" alt="Logo" />
                        </Link>
                      </div>
                      <div class="menu-outer">
                        <div class="menu-wrap">
                          <nav class="menu-nav">
                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                              <ul class="navigation">
                                <li class="active ">
                                  <Link to="/" onClick={toggleClosebtn}>
                                    Home
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/about-us" onClick={toggleClosebtn}>
                                    About Us
                                  </Link>
                                </li>
                                <li>
                                  {/* <Link
                                    to="/portfolio"
                                    onClick={toggleClosebtn}
                                  >
                                    Case Studies
                                  </Link> */}
                                </li>
                                <li>
                                  <Link to="/blogs" onClick={toggleClosebtn}>
                                    Blog
                                  </Link>
                                </li>
                                <li class=" menu-item-has-children">
                                  <Link
                                    to="/frontent-development"
                                    onClick={toggleClosebtn}
                                  >
                                    Services
                                  </Link>
                                  <ul class="sub-menu">
                                    <li class="">
                                      <Link to="/frontent-development">
                                        Frontent Development
                                      </Link>
                                    </li>
                                    <li class="">
                                      <Link to="/backend-development">
                                        Backend Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/blockchain-development">
                                        Blockchain Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/blockchain-development">
                                        Web Development
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/cloud-services">
                                        Cloud Service
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/UIUX">UI/UX Service</Link>
                                    </li>
                                    <li>
                                      <Link to="/SEO">SEO Service</Link>
                                    </li>
                                    <li>
                                      <Link to="/SMM">SMM Service</Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/contact-us"
                                        onClick={toggleClosebtn}
                                      >
                                        Contact Us
                                      </Link>
                                    </li>
                                  </ul>
                                  <div class="dropdown-btn">
                                    <span class="fas fa-angle-down"></span>
                                  </div>
                                </li>
                                <li>
                                  <Link
                                    to="/portfolio"
                                    onClick={toggleClosebtn}
                                  >
                                    Case Studies
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/contact-us"
                                    onClick={toggleClosebtn}
                                  >
                                    Contact Us
                                  </Link>
                                </li>
                                <li>
                                  <Link to={"/appointment"} exact mode="dark">
                                    <button
                                      style={{
                                        border: "2px solid #f89e52",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                      // onClick={handleDownload}
                                      className="nav-link Book fsnEF"
                                    >
                                      Free Strategy Call{" "}
                                    </button>
                                  </Link>
                                  {/* <Link
                                    to="/appointment"
                                    onClick={toggleClosebtn}
                                  >
                                    <button className="mobile-btn ml-5">
                                      Free Strategy call{" "}
                                    </button>
                                  </Link> */}
                                </li>
                              </ul>
                            </div>
                          </nav>
                        </div>
                      </div>
                      <div class="social-links">
                        {/* <ul class="clearfix list-wrap">
                          <li>
                            <a
                              target={"_blank"}
                              href="https://www.linkedin.com/company/quantum-bases/"
                            >
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                            
                          </li>
                        </ul> */}
                        {/* <Link to="/appointment" onClick={toggleClosebtn}>
                          <button className="mobile-btn ml-5">
                            Free Strategy call{" "}
                          </button>
                        </Link> */}
                      </div>
                    </nav>
                  </div>
                )}
                <div class="menu-backdrop"></div>
                {/* <!-- End Mobile Menu --> */}
              </div>
            </div>
          </div>

          <div class="body-contact-overlay"></div>
          {/* <!-- header-contact-end --> */}
        </div>
      </header>
    </div>
  );
};

export default Header;
