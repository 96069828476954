import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>SEO software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <div className="breadcrum-area details pt-175 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-5 ">
                <h1 className="title">SEO Consulting Service</h1>
                <p>
                  Elevate your online presence through our SEO consulting
                  services. Our dedicated team strives to enhance your website's
                  search engine rankings, ensuring increased visibility and
                  effortless discovery by a larger audience. Leverage our
                  expertise to illuminate your digital footprint, making your
                  online identity more vibrant than ever. With our tailored
                  approach, expect your platform to soar high in search results,
                  attracting organic traffic and garnering wider recognition.
                  Let us optimize your online visibility, aligning your content
                  with search engine algorithms for maximum impact. Experience
                  the power of effective SEO, transforming your virtual
                  prominence into a shining beacon.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <h1 className="title text-center pt-5 pb-5">Our Process</h1>
            <div className="col-lg-6">
              <img src="/assets/img/images/process.svg" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="pt-40">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Keyword Magic</h4>
                    <p>
                      In our SEO consulting services, we find out what words
                      people use when searching for things like yours. These are
                      called keywords. Our team figures out the best keywords
                      for your website to show up on search engines.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Content Charm</h4>
                    <p>
                      We help you create content that's helpful and interesting.
                      This could be articles, videos, or other things. Good
                      content makes search engines happy and brings more
                      visitors to your site.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Technical Tweaks</h4>
                    <p>
                      Our experts work behind the scenes to make sure your
                      website is easy for search engines to understand. We fix
                      things like how your website is organized and how fast it
                      loads. This makes your site more likely to appear in
                      search results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h1 className="text-center pt-5 pb-5">
              Our SEO Consulting Services:
            </h1>
            <div className="col-lg-4">
              <div
                className="border_cards p-4 mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/ux.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Keyword Research</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We find the best words that people use to search for things
                  related to your website. Through thorough research and
                  analysis, we optimize content with relevant keywords, ensuring
                  higher visibility and attracting targeted traffic to enhance
                  your online presence and engagement.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/html.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">On-Page Optimization</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We make sure your website's content and structure are friendly
                  for search engines. Our SEO experts optimize every aspect,
                  from keywords to meta tags, ensuring high visibility and
                  ranking on search engine results pages (SERPs). This strategic
                  approach drives organic traffic and boosts your online
                  presence .
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/client.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Link Building</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We help your website get linked to from other trusted
                  websites, boosting your online credibility. By leveraging
                  strategic partnerships and employing effective SEO techniques,
                  we enhance your visibility and authority in the digital
                  landscape, driving organic traffic and fostering user trust.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/responsive.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Content Creation</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We create high-quality articles and materials that attract
                  visitors and search engines alike. Our content is meticulously
                  crafted to engage audiences and rank well on search engine
                  results pages (SERPs). This strategic approach ensures maximum
                  visibility and organic traffic, contributing to overall
                  business success .
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/perform.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Technical SEO</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We fix behind-the-scenes stuff on your website to make sure
                  search engines can understand it easily. Our optimization
                  techniques enhance visibility and ranking by ensuring your
                  content is structured, labeled, and coded for maximum search
                  engine comprehension and indexing.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/deploy.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Performance Tracking</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We keep a close eye on how well your website is performing in
                  search results and make necessary adjustments. Our continuous
                  monitoring and optimization strategies ensure that your
                  website maintains visibility and ranks higher, driving organic
                  traffic and enhancing your online presence effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "90px 0" }}>
            <div className="col-lg-6">
              <h2 style={{ fontSize: "40px" }}>
                Let’s build a product that gets results!
              </h2>
              <p style={{ textAlign: "justify" }}>
                Get ready to boost your website's visibility with improved SEO
                services and see your rank rise in no time. Let's make your
                online presence shine brighter!
              </p>
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Consulting Service</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>API Integrations</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>End to End Support</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Prototyping Expertise</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/points.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEO;
