import React from "react";
import { Helmet } from "react-helmet";

const Webdevelopment = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>Web Development software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <div>
        <div className="breadcrum-area details pt-175 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="pt-5 ">
                  <h1 className="title"> Web Development Service</h1>
                  <p>
                    We're here to build your website just the way you want it.
                    Our team creates the pages, buttons, and everything else
                    that makes your website work. With our expertise, your
                    website will come to life and be ready to impress visitors
                    from all around.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <h1 className="title text-center pt-5 pb-5">Our Process</h1>
              <div className="col-lg-6">
                <img src="/assets/img/images/process.svg" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="pt-40">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Design Discussion</h4>
                      <p>
                        We talk to you about how you want your website to look
                        and what it should do. This helps us understand your
                        vision and goals.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Building Phase</h4>
                      <p>
                        Our team starts creating the actual website. We put
                        together all the pages, pictures, buttons, and features
                        you need. This is where your website starts taking
                        shape.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#f89e52",
                        padding: "10px 15px",
                        borderRadius: "50%",
                        marginRight: "20px",
                        position: "relative",
                        top: "-60px",
                      }}
                    >
                      <i
                        class="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffffff" }}
                      ></i>
                    </div>
                    <div>
                      <h4>Testing and Launch</h4>
                      <p>
                        Before your website goes live, we test everything to
                        make sure it works smoothly. Once everything is perfect,
                        we launch your website for the world to see.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h1 className="text-center pt-5 pb-5">
                Web Development Services
              </h1>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4 mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/ux.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Custom Website Creation</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We Build Websites From Scratch, Tailored To Your Specific
                    Needs And Preferences. Our team of experienced developers
                    and designers ensures every aspect of your website, from
                    layout to functionality, aligns perfectly with your brand
                    identity. We prioritize user-friendly interfaces, mobile
                    responsiveness, SEO optimization
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/html.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Responsive Design</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    Our websites work well on all devices - from big screens to
                    smartphones. We ensure responsive design, fast loading
                    times, and intuitive navigation. Additionally, we optimize
                    for various browsers and operating systems, ensuring a
                    seamless experience for all users.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/client.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">User-Friendly Layouts</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We create easy-to-navigate designs, so visitors can find
                    what they need quickly. Our approach involves user-centric
                    design principles, intuitive interfaces, and streamlined
                    navigation. Additionally, we conduct user testing to refine
                    the user experience and ensure optimal usability across
                    devices and platforms
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/responsive.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">E-Commerce Solutions </h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We set up online stores where you can sell products or
                    services, ensuring a seamless shopping experience for both
                    sellers and buyers. Our platforms are equipped with
                    customizable storefronts, secure payment processing,
                    inventory management, marketing tools, analytics, and robust
                    customer support services.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="img_size d-flex justify-content-center"
                      src="/assets/img/images/perform.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Content Management</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    We create user-friendly platforms enabling seamless content
                    updates and website management. Our dedicated technical
                    support remains available post-launch, ensuring smooth
                    operation, continuous improvement, and a hassle-free,
                    efficient experience for our valued clients.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="border_cards p-4  mb-4 details-services"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: "20px",
                    textAlign: "center",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className=" img_size d-flex justify-content-center"
                      src="/assets/img/images/deploy.png"
                      alt=""
                    />
                  </div>
                  <h4 className="pt-4 pb-4">Testing Magic</h4>
                  <p className="text-justify" style={{ textAlign: "justify" }}>
                    Before you use it, we test everything to fix any problems,
                    ensuring your experience is hassle-free and enjoyable. Our
                    rigorous testing process covers all aspects, guaranteeing
                    top-notch performance, reliability, and user satisfaction,
                    ultimately providing peace of mind and seamless usage.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "90px 0" }}>
              <div className="col-lg-6">
                <h2 style={{ fontSize: "40px" }}>
                  Let’s build a product that gets results!
                </h2>
                <p style={{ textAlign: "justify" }}>
                  Get ready to have an amazing website that looks great on all
                  devices. Our improved custom web development services will
                  make your site attractive and easy to use for everyone. Let's
                  create something special together!
                </p>
                <div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: "white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Consulting Service</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: "white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>API Integrations</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>End to End Support</h6>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        style={{
                          background: "#1f6eff",
                          padding: "15px 20px",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <i
                          class="fa-solid fa-check"
                          style={{ color: " white", marginRight: "20px" }}
                        ></i>
                        <h6 style={{ color: "white" }}>
                          Prototyping Expertise
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/assets/img/images/points.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webdevelopment;
