import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { constant } from "../../config/config";
import Shimmer from "../Blogs/shimmer";
import { Helmet } from "react-helmet";




const Portfolio = () => {
  const [portfoliolist, setPortfolioList] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchBlogList = async () => {
      const token =
        "ff0e86a1e60669c61efca944bc5370545f073a4a2feffcbea9ea99210c81ecce0280c044d131ce5a4f2e4ef367c632d97cc0947e0284aa30c24ab64080b44bf0254f8f3f35702345f627b459626b0ad42f3ae8c9db7d2353b1277cd449ba06ec6184ca9011a53d622216965a76da25b935a2a50c0ce9660b063d1daee435af8c";

      try {
        const response = await axios.get(
          `${constant.apiUrl}/api/portfolios?populate=*`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPortfolioList(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchBlogList();
  }, []);
  return (
    
    <div className="container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Portfolios software development services </title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
      <section className="breadcrumb-area pt-175 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">Portfolio</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Portfolio
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-shape-wrap">
          <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
          <img src="assets/img/images/breadcrumb_shape02.png" alt="" />
        </div>
      </section>
      <div className="blogs pt-40">
        {loading ? (
          <div  >
            <  Shimmer /><br />
            <Shimmer />
          </div>
        ) : portfoliolist.length > 0 ? (
          portfoliolist.map((data) => {
            const displayData = data.attributes?.portfolioImages?.data[0];
            const imageUrl = displayData?.attributes?.url;
            // console.log(imageUrl);
            return (
              <div className="row  pb-4" key={data.id}>
                <div className="col-lg-4">
                  {imageUrl ? (
                    <img
                      src={`${constant.apiUrl}${imageUrl}`}
                      alt={data.attributes.Title || "Blog Image"}
                    />
                  ) : (
                    <div>No Image Available</div>
                  )}
                </div>
                <div className="col-lg-8">
                  <h3>{data.attributes.heading}</h3>
                  <p>{data.attributes.description}</p>
                  <Link className='usama px-4 py-2' to={`/portfolio-detail?portfolioId=${data.id}`}>View More</Link>
                </div>
              </div>
            );
          })
        ) : (
          <p>No Blog items found.</p>
        )}
      </div>
    </div>
    // <div>
    //   <main>
    //     <div className="container">
    //       <div className="row">
    //         {userData.map((project) => (
    //           <div className="col-lg-6 mb-40" key={project._id}>
    //             <Link to={`/portfolio/portfolio-detail?projectName=${project._id}`}>
    //               <div
    //                 className="portfoliocards"
    //                 data-aos="fade-up"
    //                 data-aos-duration="1400"
    //               >
    //                 <h2 className="title text-center pt-3 pb-3">{project.heading}</h2>
    //                 <img
    //                   src={project.portfolioImage} // Update property name
    //                   alt="portfolio"
    //                 />
    //               </div>
    //             </Link>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </main>
    // </div>
  );
};

export default Portfolio;