import React from "react";
import { Helmet } from "react-helmet";

const UIUIX = () => {
  return (
    <div>
<Helmet>
<meta charSet="utf-8" />
<title>UIUX software development services </title>
{/* <link rel="canonical" href="http://mysite.com/example" /> */}
</Helmet>

      <div className="breadcrum-area details pt-175 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="pt-5 ">
                <h1 className="title">UI/UX Design</h1>
                <p>
                  UI, which stands for User Interface, is like the face of a
                  website or app. It's how everything looks and where you can
                  click. UX, or User Experience, is how it feels when you use
                  the website or app. It's like if everything works smoothly and
                  makes sense. Our UI/UX design solution helps make websites and
                  apps look nice and work well. Our team takes care of how
                  things appear on the screen and how easy it is for you to use
                  them. So, if you need help with making your digital stuff look
                  cool and be easy to use, we're here for you!
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/serviceh.svg" alt="serviceh" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <h1 className="title text-center pt-5 pb-5">Our Process</h1>
            <div className="col-lg-6">
              <img src="/assets/img/images/process.svg" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="pt-40">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Listen Client’s Reservation</h4>
                    <p>
                      First, we talk to clients to understand what type of UI/UX
                      design solution they want. We ask questions to learn about
                      their ideas and goals. This helps us know what colors,
                      shapes, and styles you like. We want to make sure we're on
                      the same page before we start.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Design Magic</h4>
                    <p>
                      Once we know what you want, our UI/UX design solution
                      wizards start working. They create beautiful layouts,
                      buttons, and icons that match your ideas. They make sure
                      everything looks pretty and is easy to find and use. We
                      show you the designs and listen to your feedback to make
                      any changes you want.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      background: "#f89e52",
                      padding: "10px 15px",
                      borderRadius: "50%",
                      marginRight: "20px",
                      position: "relative",
                      top: "-60px",
                    }}
                  >
                    <i
                      class="fa-solid fa-magnifying-glass"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </div>
                  <div>
                    <h4>Smooth and Smart</h4>
                    <p>
                      After the UI/UX design solution is set, our tech experts
                      step in. They make sure everything works smoothly behind
                      the scenes. Buttons click, pages swipe, and everything
                      just feels right. We test and fix anything that might
                      cause trouble. Once we're sure everything is perfect, we
                      hand over the magic to you! That's how we make sure your
                      digital stuff is not just pretty, but also easy and fun to
                      use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <h1 className="text-center pt-5 pb-5">
              UI/UX design Solution Services
            </h1>
            <div className="col-lg-4">
              <div
                className="border_cards p-4 mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/ux.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Pretty Designs</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We make things look nice! Our team creates beautiful colors,
                  pictures, and layouts for your website or app. With a keen eye
                  for design trends and user preferences, we craft visually
                  stunning interfaces that captivate and engage your audience,
                  enhancing their overall experience
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/html.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Easy Clicks</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We design buttons and menus that are simple to use, ensuring
                  you can find what you need without any confusion. Our
                  user-friendly interface enhances navigation, making tasks
                  efficient and straightforward. With clear labeling and
                  intuitive layouts, our design philosophy prioritizes ease of
                  use .
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/client.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Friendly Layouts</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  Our experts organize everything neatly. This makes it easy for
                  you to find information and move around smoothly. From
                  categorizing data to optimizing navigation, we ensure a
                  streamlined experience that enhances productivity and
                  satisfaction Our experts organize everything neatly.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/responsive.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Helpful Icons </h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  We use small pictures (icons) that show you what things do,
                  like having little helpers to guide you. These intuitive
                  visuals enhance understanding and streamline your interaction,
                  ensuring a user-friendly experience that's both informative
                  and enjoyable acting as little helpers.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/perform.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Smooth Interactions</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                  When you tap or swipe, things happen just like you expect. Our
                  work makes sure everything feels natural and not strange. We
                  meticulously design interactions to be intuitive, responsive,
                  and in line with user expectations, ensuring a seamless and
                  delightful user experience that feels effortless and familiar.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="border_cards p-4  mb-4 details-services"
                style={{
                  border: "1px solid #dfdfdf",
                  borderRadius: "20px",
                  textAlign: "center",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="img_size d-flex justify-content-center"
                    src="/assets/img/images/deploy.png"
                    alt=""
                  />
                </div>
                <h4 className="pt-4 pb-4">Testing Magic</h4>
                <p className="text-justify" style={{ textAlign: "justify" }}>
                Before you use it, we test everything to fix any problems, ensuring your experience is hassle-free and enjoyable. Our meticulous testing process covers all functionalities and scenarios to eliminate any potential issues. This commitment to quality and reliability guarantees a seamless user experience. 
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ padding: "90px 0" }}>
            <div className="col-lg-6">
              <h2 style={{ fontSize: "40px" }}>
                Let’s build a product that gets results!
              </h2>
              <p style={{ textAlign: "justify" }}>
                Get ready for fantastic outcomes by upgrading your UI/UX design
                solution services. Improved design and usability lead to
                incredible results!
              </p>
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Complex Enterprise</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Consulting Service</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: "white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Scaling Flexibility</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>API Integrations</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>End to End Support</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      style={{
                        background: "#1f6eff",
                        padding: "15px 20px",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      {/* <FontAwesomeIcon icon="fa-solid fa-check" /> */}
                      <i
                        class="fa-solid fa-check"
                        style={{ color: " white", marginRight: "20px" }}
                      ></i>
                      <h6 style={{ color: "white" }}>Prototyping Expertise</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/img/images/points.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUIX;
