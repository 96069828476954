import React from "react";
import ContentLoader from "react-content-loader";

const Shimmer = (props) => (
  <ContentLoader
    speed={1}
    width={1300} // Adjust the width based on your container or use "100%"
    height={200}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="150" />
    <rect x="0" y="160" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="190" rx="5" ry="5" width="100%" height="10" />
    <rect x="0" y="210" rx="5" ry="5" width="100%" height="10" />
    <rect x="0" y="230" rx="5" ry="5" width="100%" height="10" />
  </ContentLoader>
);

export default Shimmer;

